<template>
  <div class="full-width full-height bg-blue p-sm">
    <back url="/resource/index" />

    <div class="cameral-list m-b-sm m-t-sm">
      <!-- <span class="before-line text-white p-w-md relative">摄像机列表</span> -->
      <div class="hd flex items-center justify-between m-t-sm">
        <div class="flex flex-one items-center flex-end text-lightblue">
          <span class="flex-s m-r-xs" v-show="$g.isDepart">省份：</span>
          <a-select
            placeholder="请选择省份"
            v-model="regionId"
            @change="changeProvince"
            class="m-r-sm search-mx-wd-1"
            v-show="$g.isDepart"
          >
            <a-select-option
              v-for="item in provinceList"
              :key="item.regionCode"
              :value="item.regionCode"
            >
              {{ item.alias }}
            </a-select-option>
          </a-select>
          <span class="flex-s m-r-xs">管辖单位：</span>
          <a-cascader
            :options="organizationList"
            :display-render="displayRender"
            v-model="organizationId"
            class="m-r-sm search-mx-wd-1"
            expand-trigger="hover"
            placeholder="管辖单位"
            change-on-select
            @change="selectOrganization"
          />
          <span class="flex-s m-r-xs">路线：</span>
          <a-select
            placeholder="请选择所属路线"
            v-model="roadId"
            class="m-r-sm search-mx-wd-1"
            @change="selectRoad"
          >
            <a-select-option
              v-for="item in roadList"
              :key="item.roadId"
              :value="item.roadId"
            >
              {{ item.roadCode + ' ' + item.roadName }}
            </a-select-option>
          </a-select>
          <span class="flex-s m-r-xs">位置类型：</span>
          <a-select
            placeholder="位置类型"
            v-model="classify"
            @change="selectClassify"
            class="m-r-sm search-mx-wd-1"
          >
            <a-select-option
              v-for="item in classifyList"
              :key="item.codeValue"
              :value="item.codeValue"
            >
              {{ item.codeName }}
            </a-select-option>
          </a-select>
          <span class="flex-s m-r-xs">状态：</span>
          <a-select
            placeholder="请选择摄像机状态"
            v-model="status"
            @change="selectStatus"
            class="m-r-sm search-mx-wd-1"
          >
            <a-select-option
              v-for="item in statusArr"
              :key="item.id"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-button
            type="primary"
            class="m-r-sm btn-search"
            @click="handleSearch"
          >
            搜索
          </a-button>
          <a-button class="btn-reset" @click="resetHandle">
            重置
          </a-button>
          <span class="span-line m-l-md m-r-md"></span>
          <a-button class="btn-export" @click="exportCameraList">
            数据导出
          </a-button>
        </div>
      </div>
      <div class="hd3 m-t-sm">
        <div class="relative full-width">
          <a-table
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :columns="columns"
            :rowKey="(record, index) => record.cameraNum"
            :components="resibleTableHeader"
            :data-source="cameraInfoList"
            :pagination="paginationOpt"
            :scroll="scroll"
            :loading="loading"
            bordered
          >
            <span
              slot="customOrganization"
              slot-scope="text"
              :title="text"
              class="block ellipsis"
            >
              {{ text }}
            </span>
            <!-- <span
                slot="customStatus"
                slot-scope="text"
                :style="{
                  color:
                    text === '0'
                      ? '#7a7a7a'
                      : text === '1'
                      ? '#386499'
                      : '#e98786',
                }"
              >
                {{ text | getCameraStatus }}
              </span> -->
            <span
              slot="customStatus"
              class="flex items-center"
              slot-scope="text"
            >
              <span
                :class="{
                  'm-r-xs icon-style': true,
                  online: String(text) === '1',
                  offline: String(text) === '0',
                  error: String(text) === '2',
                }"
              ></span
              >{{ text | getCameraStatus }}
            </span>
            <span slot="customClassify" slot-scope="text">
              {{ text | getClassify }}
            </span>
            <span slot="customDirection" slot-scope="text">
              {{ text | getDirectionArrow }}
            </span>
            <span slot="customCameraType" slot-scope="text">
              {{ text | getCameraType }}
            </span>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from '@/components/Back';
import { mapActions, mapState } from 'vuex';
import { handleExportFile } from '@/tools';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'CameralJoin',
  mixins:[ResizableTable],
  data() {
    return {
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getCameraList();
          // this.searchCameraFrom(); //显示列表的接口名称
          // this.searchCameraFromTest();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this._getCameraList();
          // this.searchCameraFrom();
          // this.searchCameraFromTest();
        },
      },
      columns: [
        {
          title: '序号',
          dataIndex:'cameraIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
        },
        {
          title: '地区',
          dataIndex: 'monitorRegionName',
          key: 'monitorRegionName',
          width:100,
          ellipsis: true,
        },
        {
          title: '管辖单位',
          dataIndex: 'organizationName',
          key: 'organizationName',
          width:200,
          scopedSlots: { customRender: 'customOrganization' },
          ellipsis: true,
        },
        {
          title: '所属路线',
          dataIndex: 'roadCode',
          key: 'roadCode',
          width:120,
          ellipsis: true,
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameraName',
          key: 'cameraName',
          width:200,
          ellipsis: true,
        },
        {
          title: '桩号',
          dataIndex: 'kmPile',
          key: 'kmPile',
          width:140,
          ellipsis: true,
        },
        {
          title: '经纬度',
          dataIndex: 'longAndLatiNew',
          key: 'longAndLatiNew',
          width: 140,
          ellipsis: true,
        },
        {
          title: '方向',
          dataIndex: 'derectionCode',
          key: 'derectionCode',
          width: 100,
          scopedSlots: { customRender: 'customDirection' },
        },
        {
          title: '位置类型',
          dataIndex: 'classifyCode',
          key: 'classifyCode',
          width: 100,
          scopedSlots: { customRender: 'customClassify' },
        },
        {
          title: '摄像机类型',
          dataIndex: 'cameraType',
          key: 'cameraType',
          width: 100,
          ellipsis:true,
          scopedSlots: { customRender: 'customCameraType' },
        },
       {
          title: '兴趣点',
          dataIndex: 'poiName',
          key: 'poiName',
          width:140,
           //scopedSlots: { customRender: 'customClassify' }, 
        },
        {
          title: '状态',
          dataIndex: 'onlineStatus',
          key: 'onlineStatus',
          width:80,
          scopedSlots: { customRender: 'customStatus' },
          // ellipsis: true,
        },
        {
          title: '更新时间',
          dataIndex: 'gmtModified',
          key: 'gmtModified',
          width:120,
          ellipsis: true,
        },
      ],
      data: [],
      selectedRowKeys: [],
      regionId: undefined,
      organizationId: [],
      roadId: undefined,
      classify: undefined,
      status: undefined,
      statusArr: [
        {
          id: 0,
          value: '1',
          name: '在线',
        },
        {
          id: 1,
          value: '0',
          name: '离线',
        },
        {
          id: 2,
          value: '2',
          name: '故障',
        },
      ],
      scroll: { y: window.innerHeight - 340 ,x:'100%'},
      loading: false,
      toggleType: '',
    };
  },
  components: {
    Back,
  },
  computed: {
    ...mapState({
      cameraInfoList: (state) => state.home.cameraInfoList,
      provinceList: (state) => state.collect.provinceList,
      organizationList: (state) => state.collect.organizationList,
      roadList: (state) => state.collect.roadList,
      classifyList: (state) => state.collect.classifyList,
      // cameraTypeList: (state) => state.collect.cameraTypeList,
    }),
  },
  mounted() {
    this.getProvince({ regionLevel: 1 });
    this.getOrganationList();
    this.getRoadList();
    this.getClassifyList('CLASSIFY');
    // this.getCameraType('CAMERA_TYPE');
    this._getCameraList();
  },
  watch: {
    regionId(nv, ov) {
      if (nv && nv !== ov) {
        this.selectDownInfo(nv);
      }
    },
  },
  methods: {
    ...mapActions([
      'getCameraInfoByList',
      'getProvince',
      'getOrganationList',
      'getRoadList',
      'getClassifyList',
      // 'getCameraType',
    ]),
    _getCameraList() {
      let data = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        openStatus: '',
        reportSource: '',
        cameraName: '',
        regionId: this.regionId,
        organizationId:
          this.organizationId.length > 0
            ? this.organizationId[this.organizationId.length - 1]
            : '',
        roadId: this.roadId,
        derectionCode: '',
        classifyCode: this.classify,
        ptzControlStatus: '',
        transcodingName: '',
        cameraType: '',
        onlineStatus: this.status,
        cameraNum: '',
      };
      this.loading = true;
      this.getCameraInfoByList(data).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.paginationOpt.total = res.total;
        }
      });
    },
    //导出摄像机
    exportCameraList() {
      if (!this.hasPermission(109211141010)) {
        this.permissionWarning();
        return;
      }

      let params = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        openStatus: '',
        reportSource: '',
        cameraName: '',
        regionId: this.regionId,
        organizationId:
          this.organizationId.length > 0
            ? this.organizationId[this.organizationId.length - 1]
            : '',
        roadCode: this.roadId,
        derectionCode: '',
        classifyCode: this.classify,
        ptzControlStatus: '',
        transcodingName: '',
        cameraType: '',
        onlineStatus: this.status,
        cameraNum: this.selectedRowKeys.join(','),
      };
      let objParams = {
        data: params,
        instructionsDel: {
          module: '接入数据',
          page: '',
          feature: '数据导出',
          description: '摄像机列表',
        },
      };
      this.$api.aiCenterY.exportCameraAll(objParams).then((res) => {
        handleExportFile(res, '摄像机列表.xls');
      });
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    selectOrganization(value,options) {
      this.organizationId = value;
      this.organizationCurrentId =
        this.organizationId.length > 0
          ? this.organizationId[this.organizationId.length - 1]
          : '';
      
      //联动
      this.toggleType = 'organization';
      if(this.organizationCurrentId === options[0].regionCode) {
        this.organizationId = [];
        return;
      }
      let tempRegionCode = this.regionId;
      this.regionId = undefined;
      if(this.$g.isDepart) {
        this.getProvince({
          organizationId: value?.length > 0 && value[value.length - 1],
          commitFlag:false
        }).then((res) => {
          if (res.code == 200) {
            let initRegionCode = res.data?.length?res.data[0].regionCode:undefined;
            let newRegionCode = res.data?.length?res.data.find(item => item.regionCode === tempRegionCode): null;
            this.regionId = newRegionCode?newRegionCode.regionCode:initRegionCode;
          }
        });
      }
      
    },
    selectStatus(value) {
      // console.log(`selected ${value}`);
      this.status = value;
    },
    changeProvince(val) {
      this.regionId = val;
      //联动组织单位和路线
      this.getRoadList({ regionCode: val });
      this.getOrganationList({ regionCode: val });
      /* this.roadId = undefined;
      this.organizationId = []; */
    },
    selectRoad(e) {
      this.roadId = e;
      //联动
      this.toggleType = 'road';
      if(this.$g.isDepart) {
         let tempRegionCode = this.regionId;
        this.regionId = undefined;
        this.getProvince({ roadId: e ,commitFlag:false}).then((res) => {
          // console.log(res)
          if (res.code == 200) {
            // this.regionId = res.data?.length?res.data[0].regionCode:undefined;
            let initRegionCode = res.data?.length?res.data[0].regionCode:undefined;
            let newRegionCode = res.data?.length?res.data.find(item => item.regionCode === tempRegionCode): null;
            this.regionId = newRegionCode?newRegionCode.regionCode:initRegionCode;
          }
        });
      }
      
    },
    selectDownInfo(nv) {
      if (this.toggleType == 'road') {
        this.getOrganationList({ regionCode: nv });
      } else if (this.toggleType == 'organization') {
        this.getRoadList({ regionCode: nv });
      }
    },
    selectClassify(e) {
      this.classify = e;
    },
    handleSearch() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this.toggleType = '';
      this._getCameraList();
    },
    resetHandle() {
      this.regionId = undefined;
      this.organizationId = [];
      this.roadId = undefined;
      this.classify = undefined;
      this.status = undefined;

      this.getProvince({ regionLevel: 1 });
      this.getOrganationList();
      this.getRoadList();
      this.toggleType = '';
      this.handleSearch();
    },
    onSelectChange(selectedRowKeys) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys);

      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>

<style scoped></style>
